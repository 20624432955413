import { put, takeEvery } from 'redux-saga/effects';
import {
  toastNotifyError,
  toastNotifyErrorCloseAutoClose,
} from '../../components/Common/Toast';
import { ANDROID_UPI_INTENT_LINK } from '../../config/paymentLinks';

import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';

import {
  savePaymentMethods,
  savePostPaymentData,
  setLoaderState,
  redirectAfterPayment,
  toggleLazyPayAuthModal,
  saveAvailablePaymentMethods,
  setPaymentModalVisiblity,
  setPaymentModalOptionsVisiblity,
  setGlobalLoader,
  setPrepaidLimitDisablePayNow,
} from '../actions';

import {
  INIT_PAYMENT,
  INIT_PAYMENT_FOR_QR,
  POLL_PAYMENT_STATUS,
  VERIFY_PAYMENT,
  FETCH_PAYMENT_METHODS,
  REDIRECT_AFTER_PAYMENT,
  INIT_LAZY_PAY_PAYMENT,
  VERIFY_LAZY_PAY_OTP,
  GET_AVAILABLE_PAYMENT_METHODS,
  CHECK_CARD_INFO,
  PRE_PAYMENT_FOR_MDR_TRX,
  TOGGLE_PARTIAL_PAYMENT_MODE,
} from '../actionTypes';

import store from '../store';
import { PATH, getRoute } from './../../utils/routes';
import {
  LAZYPAY_AUTH_MODAL_VIEWS,
  TRANSACTION_ID_CONSTANT,
  INIT_PAYMENT_DEFAULT_PHONE,
} from '@/utils/constants';
import { rejectPayNowIfNoPaymentMethodsReceived } from '@/utils/rejectPayNowIfNoPaymentMethodsReceived';
import { rejectPayNowPaymentMethodReceivedMsg } from '@/utils/rejectPayNowPaymentMethodReceivedMsg';
import { SessionStorageHelper } from '@/utils/SessionStorageHelper';
import getIntentLink from '@/utils/getIntentLink';
import { setPurchaseTrigger } from 'src/utils/purchaseEventTrigger';
import { redirectToFailedPaymentPage } from 'src/utils/paymentUtils';

// payment initialization
function* initPayment(actions) {
  try {
    actions.data.phone = actions.data.phone || INIT_PAYMENT_DEFAULT_PHONE;
    yield put(setLoaderState(true));
    const storeData = store.getState().storeReducer.store;
    const paymentResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PAYMENT.INIT_PAYMENT,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse?.data?.status) {
      yield put(setLoaderState(false));
      const response = paymentResponse?.data?.data;
      let intentLink = getIntentLink(actions.data.paymentMethod);

      if (
        actions.data.paymentMethod === 'phonepe' ||
        actions.data.paymentMethod === 'googlepay' ||
        actions.data.paymentMethod === 'paytmupi' ||
        actions.data.paymentMethod === 'whatsapp'
      ) {
        intentLink = intentLink.replace('{deeplink}', response.data.url);
        window.location.href = intentLink;
        sessionStorage.setItem(TRANSACTION_ID_CONSTANT, response.txnId);
      } else {
        if (['paytm', 'airtel'].includes(response.pg)) {
          window.location.href = response.data.url;
        } else {
          yield put(savePostPaymentData(response));
        }
      }
      actions.router.push(
        getRoute(PATH.paymentProcess(response.txnId), storeData.store_info.domain)
      );
    } else {
      yield put(setLoaderState(false));
      toastNotifyError('Payment Failed.');
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Payment failed');
  }
}

/**
 * Payment initialization
 * for QR
 * @param {*} actions
 */
function* initPaymentForQR(actions) {
  try {
    actions.data.phone = actions.data.phone || INIT_PAYMENT_DEFAULT_PHONE;
    yield put(setLoaderState(true));

    const paymentResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PAYMENT.INIT_PAYMENT,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse?.data?.status) {
      yield put(setLoaderState(false));
      const response = paymentResponse?.data?.data;
      // Creating a Intent Link
      const intentLink = ANDROID_UPI_INTENT_LINK.replace('{deeplink}', response.data.url);
      sessionStorage.setItem(TRANSACTION_ID_CONSTANT, response.txnId);

      // Send data back from where payment initiated
      actions.callback &&
        actions.callback({
          status: true,
          intentLink,
          payment: paymentResponse.data.response,
          transactionId: response.txnId,
        });
    } else {
      yield put(setLoaderState(false));

      // Send data back from where payment initiated
      actions.callback &&
        actions.callback({
          status: false,
          payment: paymentResponse.data.response,
          message: paymentResponse?.data?.message,
        });
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Something went bad! Please try again later.');
  }
}

function* initLazyPayPayment(actions) {
  try {
    actions.data.phone = actions.data.phone || INIT_PAYMENT_DEFAULT_PHONE;
    yield put(setLoaderState(true));
    const paymentResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PAYMENT.INIT_LAZY_PAY_PAYMENT,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse && paymentResponse.data.status) {
      const response = paymentResponse.data.data;
      sessionStorage.setItem(TRANSACTION_ID_CONSTANT, response.txnID);

      if (response.is_eligible) {
        if (response.is_otp_required) {
          yield put(
            toggleLazyPayAuthModal({
              show: true,
              view: LAZYPAY_AUTH_MODAL_VIEWS.OTP,
            })
          );
        } else {
          yield put(
            toggleLazyPayAuthModal({
              show: true,
              // view: LAZYPAY_AUTH_MODAL_VIEWS.ELIGIBLE,
              view: LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE,
            })
          );
        }
      } else {
        yield put(
          toggleLazyPayAuthModal({
            show: true,
            view: LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE,
          })
        );
      }
    }
  } catch (error) {
    toastNotifyError('Something went bad! Please try again later.');
  } finally {
    yield put(setLoaderState(false));
  }
}

function* verifyLazyPayOtp(actions) {
  try {
    yield put(setLoaderState(true));
    const storeData = store.getState().storeReducer.store;
    const verifyResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PAYMENT.VERIFY_OTP_FOR_LAZY_PAY,
      data: actions.data,
    });

    if (verifyResponse && verifyResponse.data.status === 'success') {
      actions.router.push(
        getRoute(
          PATH.paymentProcess(verifyResponse.data.transactionId),
          storeData.store_info.domain
        )
      );
    } else {
      toastNotifyError(verifyResponse.data.message);
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

// payment polling in case of UPI
function* pollPayment(actions) {
  const storeData = store.getState().storeReducer.store;
  const orderDetails = store.getState().orderReducer.orderDetails;

  const redirectToPaymentFailurePage = () => {
    redirectToFailedPaymentPage(orderDetails?.order_hash, storeData.store_info.domain);
  };

  try {
    const pollPaymentResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PAYMENT.pollPaymentStatus(actions.data),
      parseToJson: true,
    });
    if (pollPaymentResponse?.data?.status) {
      yield put(setLoaderState(false));
      sessionStorage.removeItem(TRANSACTION_ID_CONSTANT);

      // redirect to thanku or order-status
      yield put(redirectAfterPayment(orderDetails, actions.router));
    } else {
      yield put(setLoaderState(false));
      sessionStorage.removeItem(TRANSACTION_ID_CONSTANT);
      redirectToPaymentFailurePage();
    }
    yield put(setPaymentModalVisiblity(false));
  } catch (err) {
    yield put(setLoaderState(false));
    const isPaymentFailed = err?.status >= 400 && err?.status < 500;
    if (isPaymentFailed) {
      yield put(setPaymentModalVisiblity(false));
      redirectToPaymentFailurePage();
    }
  }
}

// payment verification in case of paytm
function* verifyPayment(actions) {
  const storeData = actions.storeInfo || store.getState().storeReducer.store;
  const orderDetails = store.getState().orderReducer.orderDetails;
  const txnID = actions.data;
  const storeDomain =
    storeData?.store_info?.domain ||
    SessionStorageHelper.get('cardPaymentDetails')?.storeDomain;

  const redirectToPaymentFailurePage = () => {
    redirectToFailedPaymentPage(localStorage?.getItem('orderID'), storeDomain);
  };

  try {
    yield put(setLoaderState(true));
    const verifyPaymentResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PAYMENT.verifyPayment(txnID),
      parseToJson: true,
    });
    if (verifyPaymentResponse?.data?.status) {
      yield put(setLoaderState(false));
      yield put(redirectAfterPayment(orderDetails, actions.router, storeData));
    }
    // else if (verifyPaymentResponse?.data?.status  === "failure") {
    // } else if (verifyPaymentResponse?.data?.status  === "pending") {
    // }
    else {
      yield put(setLoaderState(false));
      redirectToPaymentFailurePage();
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Payment failed');
    redirectToPaymentFailurePage();
  }
}

// fetch all payment methods
function* fetchPaymentMethods(actions) {
  yield put(setGlobalLoader(true));
  try {
    const paymentMethodsResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PAYMENT.fetchPaymentModes(actions.data.store_id, actions.data.hash),
    });
    if (paymentMethodsResponse && paymentMethodsResponse.data) {
      yield put(savePaymentMethods(paymentMethodsResponse.data));
      /** throw error and dont show payment popup */
      if (rejectPayNowIfNoPaymentMethodsReceived(paymentMethodsResponse.data)) {
        toastNotifyErrorCloseAutoClose(rejectPayNowPaymentMethodReceivedMsg());
        yield put(setPaymentModalVisiblity(false));
        yield put(setPrepaidLimitDisablePayNow(true));
      }
      yield put(setGlobalLoader(false));
    }
  } catch (err) {
    toastNotifyError(
      'Something went bad while fetching payments methods! Try after some time.'
    );
    yield put(setGlobalLoader(false));
  }
}

function* redirectRoute(actions) {
  const storeConclave = store.getState();
  const storeData = actions.storeInfo || storeConclave?.storeReducer?.store;
  const orderData = storeConclave?.orderReducer;

  const tempCardPaymentDetails = SessionStorageHelper.get('cardPaymentDetails');

  const storeDomain =
    storeData?.store_info?.domain || tempCardPaymentDetails?.storeDomain;

  /**
   * Create a redirect route
   * according to the prepaid_flag
   * prepaid_flag === 1 : prepaid & both payment type selected
   * prepaid_flag === 0 : postpaid payment type selected
   * order_type === 4 : payment link
   * order_type === 3 : create self order
   */
  const { orderDetails, router } = actions;

  let redirectRoute = '';
  if (orderDetails.order_type === 4 || orderDetails.order_type === 3) {
    // for create bill and send payment link orders
    redirectRoute = getRoute(PATH.thankyou(orderDetails?.order_hash), storeDomain);
  } else {
    setPurchaseTrigger();
    // Redirect to orderConfirm page with parent order hash, when the current order if of partial payment type.
    if (orderDetails?.parent_order_id !== 0 && orderDetails?.partial_payment_type > 0) {
      yield put(setLoaderState(true));
      const parentOrderHash = orderData?.parentOrderDetails.order_hash;
      redirectRoute =
        orderDetails.prepaid_flag === 1
          ? getRoute(PATH.orderConfirm(parentOrderHash), storeDomain)
          : getRoute(PATH.orderStatus(parentOrderHash), storeDomain);
    } else {
      redirectRoute =
        orderDetails.prepaid_flag === 1
          ? getRoute(PATH.orderConfirm(orderDetails?.order_hash), storeDomain)
          : getRoute(PATH.orderStatus(orderDetails?.order_hash), storeDomain);
    }
  }

  /**
   * This was added as to fix bank redirection after confirming OTP on our temporary domains, as the redirection
   * only happens on domain i.e. showroom.dotpe.in & not on our path -> showroom.dotpe.in/${domain},
   * so redirection details are saved here.
   */
  if (
    tempCardPaymentDetails?.paymentMethod === 'card' &&
    tempCardPaymentDetails?.redirectionUrl
  ) {
    const path = redirectRoute.replace('d-', '');
    window.location.href = tempCardPaymentDetails.redirectionUrl + path;
    SessionStorageHelper.delete('cardPaymentDetails');
  } else router.push(redirectRoute);
}

function* fetchAvailablePaymentMethods(actions) {
  try {
    const availablePaymentMethodsResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PAYMENT.fetchAvailablePaymentModes(actions.payload.store_id),
    });
    if (availablePaymentMethodsResponse && availablePaymentMethodsResponse.data.status) {
      yield put(
        saveAvailablePaymentMethods(JSON.parse(availablePaymentMethodsResponse.data.data))
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* checkCardInfo(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PAYMENT.checkCardInfo(actions.data),
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions?.callback?.(response?.data?.status, response?.data?.data || {});
    }
  } catch (err) {
    console.log(err);
    actions?.callback?.(false, null);
  }
}

function* prePaymentForMDRTransactions(actions) {
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.PAYMENT.MDR_TRX_CHARGES,
      data: actions.data,
    });
    if (!response?.data?.status) toastNotifyError(response?.data?.message);
    actions?.callback?.(response?.data?.status);
  } catch (err) {
    if (err?.data?.message) toastNotifyError(err?.data?.message);
    actions?.callback?.(false);
  }
}

function* togglePartialPaymentMode(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.PAYMENT.TOGGLE_PARTIAL_PAYMENT,
      data: actions.data,
    });
    if (!response?.data?.status) {
      toastNotifyError(response?.data?.message);
      yield put(setPaymentModalVisiblity(false));
      yield put(setPaymentModalOptionsVisiblity(false));
    } else {
      yield put(setPaymentModalOptionsVisiblity(true));
      actions?.other?.();
      setTimeout(() => {
        actions?.callback?.();
      }, 1000);
    }
  } catch (err) {
    if (err?.data?.message) toastNotifyError(err?.data?.message);
    yield put(setPaymentModalVisiblity(false));
    yield put(setPaymentModalOptionsVisiblity(false));
  }
}

export default function* root() {
  yield takeEvery(INIT_PAYMENT, initPayment);
  yield takeEvery(INIT_PAYMENT_FOR_QR, initPaymentForQR);
  yield takeEvery(INIT_LAZY_PAY_PAYMENT, initLazyPayPayment);
  yield takeEvery(POLL_PAYMENT_STATUS, pollPayment);
  yield takeEvery(VERIFY_PAYMENT, verifyPayment);
  yield takeEvery(FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeEvery(REDIRECT_AFTER_PAYMENT, redirectRoute);
  yield takeEvery(VERIFY_LAZY_PAY_OTP, verifyLazyPayOtp);
  yield takeEvery(GET_AVAILABLE_PAYMENT_METHODS, fetchAvailablePaymentMethods);
  yield takeEvery(CHECK_CARD_INFO, checkCardInfo);
  yield takeEvery(PRE_PAYMENT_FOR_MDR_TRX, prePaymentForMDRTransactions);
  yield takeEvery(TOGGLE_PARTIAL_PAYMENT_MODE, togglePartialPaymentMode);
}
